export const VendorCategory = {
  DrCooper: 'dental',
  vintech: 'dental',
  FIADigital: 'dental',
  yousing: 'dental',
  leyan: 'dental',
  medley: 'dental',
  wemex: 'dental',
  digikar: 'dental',
  clius: 'dental',
  saloevo: 'dental',
  fujen: 'dental',
  realsun: 'western',
  vision: 'western',
  these: 'western',
  ktop: 'western',
  techgroup: 'western',
  enchukong: 'dental',
  timing: 'western',
  baihui: 'western',
  hygeai: 'western',
  linkedcare: 'western',
  esis: 'western',
  iisi: 'western',
  hugeia: 'western',
  googlecalendar: 'western',
  'sc-dr': 'western',
  pinmedpro: 'western',
  cyt: 'western',
  youngmen: 'western',
  tongxin: 'western',
};

export const getClientRegionByVendor = (vendor) => {
  const jpVendors = new Set(['medley', 'digikar', 'clius', 'saloevo', 'wemex']);

  if (jpVendors.has(vendor)) {
    return 'jp';
  }

  return 'zh';
};
