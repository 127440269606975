import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dashboard_v2/Dialog';
import FormControl from 'components/Dashboard_v2/FormControl';
import Input from 'components/Dashboard_v2/Input';
import { t } from 'i18n/config';

const InfoModal = ({ data, onConfirm, onClose }) => {
  const [info, setInfo] = useState(data);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({ ...prev, [name]: value }));
  };
  const handleConfirm = () => {
    onConfirm(info);
    onClose();
  };

  return (
    <Dialog
      open={true}
      title={t('templateName')}
      description={
        <React.Fragment>
          <FormControl>
            <Input
              name="title"
              label={t('itemName')}
              value={info.title}
              onInputChange={handleInputChange}
              maxLength={20}
              placeholder={t('exampleGroupMessageName')}
              required
            />
          </FormControl>
          <FormControl>
            <Input
              name="description"
              label={t('description')}
              value={info.description}
              onInputChange={handleInputChange}
              maxLength={20}
              placeholder={t('exampleDescription')}
            />
          </FormControl>
        </React.Fragment>
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
      disabled={!info.title}
    />
  );
};

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

InfoModal.propTypes = propTypes;

export default memo(InfoModal);
