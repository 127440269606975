import React, { useState, useMemo, useEffect, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getTemplateCategories, getTemplates } from 'lib/messageCenterService';
import { dashboard_v2 } from 'theme';
import useAlert from 'hooks/useAlert';
import DoctorSvg from 'components/Dashboard_v2/Svg/DoctorSvg';
import Modal from 'components/Dashboard_v2/Modal';
import Button from 'components/Dashboard_v2/Button';
import Loading from 'components/Dashboard_v2/Loading';
import { t } from 'i18n/config';
import { getClientRegionByVendor } from 'utils/vendors';
import { getLocaleByClientRegion } from 'utils/locale';

const { colors } = dashboard_v2;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 32px;
  }
`;

const SectionDescription = styled.p`
  margin: 24px 0;
  font-size: 14px;
  color: ${colors.SHADES_400};
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
    margin-right: 8px;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  > div:first-child {
    display: flex;
    flex-wrap: wrap;
    margin-right: 32px;
  }
`;

const Category = styled.div`
  padding: 12px 24px;
  font-size: 16px;
  color: ${({ isSelected }) => (isSelected ? colors.PRIMARY_500 : colors.SHADES_500)};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${colors.PRIMARY_500};
  }
`;

const TemplateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 18px;
`;

const Template = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  width: calc(33% - 10px);
  border-radius: 16px;
  background-color: ${colors.SHADES_000};
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));

  > div {
    margin-bottom: 32px;
    > span {
      display: inline-block;
      padding: 4px 8px;
      margin-bottom: 24px;
      color: ${colors.SHADES_500};
      background: #f1f5f9;
      border-radius: 100px;
    }
    > div {
      font-size: 20px;
      color: ${colors.SHADES_900};
      margin-bottom: 8px;
    }
    > div:last-child {
      display: block;
      color: ${colors.SHADES_500};
      font-size: 14px;
    }
  }
`;

const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
  a {
    text-decoration: none;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const TEMPLATE_TYPE_NAME = {
  patientTracking: t('tracking'),
  groupMessage: t('massMessaging'),
};

const CATEGORY_ALL = { id: 'all', name: 'all' };

const groupTemplateList = (list) =>
  list.reduce(
    (group, data) => {
      const type = data.templateType;
      const category = data.messageTemplateCategoryName;
      if (!group[type][category]) {
        Object.assign(group[type], { [category]: [data] });
      } else {
        group[type][category] = [...group[type][category], data];
      }
      group[type].all = [...group[type].all, data];
      return group;
    },
    { groupMessage: { all: [] }, patientTracking: { all: [] } }
  );

const TemplateListModal = ({ templateType, selectedId, onSelected, onClose }) => {
  const { setAlert } = useAlert({ unmountClear: true });
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const clients = useSelector((state) => state.clients.byId);
  const currentClient = clients[clientId];
  const [isLoading, setIsLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(CATEGORY_ALL);
  const [templateList, setTemplateList] = useState(null);

  const listData = useMemo(
    () => (templateList && templateList[templateType][selectedCategory.name]) || [],
    [templateList, templateType, selectedCategory]
  );

  const listCategory = useMemo(
    () => (categories && [CATEGORY_ALL, ...categories[templateType]]) || [],
    [categories, templateType]
  );

  const getTemplateData = useCallback(async () => {
    setIsLoading(true);
    try {
      const categories = await getTemplateCategories({ clientId });
      const locale = getLocaleByClientRegion(getClientRegionByVendor(currentClient.vendor));
      const list = await getTemplates({ clientId, locale });
      setCategories(categories);
      setSelectedCategory(CATEGORY_ALL);
      setTemplateList(groupTemplateList(list));
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: t('failedToLoadList', { error: error || e.message }) });
      console.error(errRes);
    } finally {
      setIsInit(true);
      setIsLoading(false);
    }
  }, [clientId, currentClient.vendor, setAlert]);

  useEffect(() => {
    getTemplateData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={true}
      title={
        <ModalTitle>
          {templateType === 'patientTracking' && t('pleaseSelectPatientTracking')}
          {templateType === 'groupMessage' && t('pleaseSelectGroupMessageTemplate')}
          <Button color="secondary" variant="outline" onClick={() => onSelected('empty')}>
            {t('useBlankTemplate')}
          </Button>
        </ModalTitle>
      }
      zIndex={2000}
      width="80vw"
      minHeight="500px"
      onClose={onClose}
    >
      {isLoading && (
        <LoadingWrapper>
          <Loading size={150} />
        </LoadingWrapper>
      )}
      {isInit && (
        <React.Fragment>
          {/* Category */}
          <CategoryWrapper>
            <div>
              {listCategory.map((category) => (
                <Category
                  onClick={() => setSelectedCategory(category)}
                  isSelected={category.name === selectedCategory.name}
                  key={category.id}
                >
                  {category.name === 'all' ? t('all') : category.name}
                </Category>
              ))}
            </div>
          </CategoryWrapper>

          {/* Template list */}
          {listData && listData.length ? (
            <TemplateWrapper>
              {listData.map((data) => (
                <Template key={data.id}>
                  <div>
                    <span>{data.messageTemplateCategoryName || t('noCategories')}</span>
                    <div>{data.title}</div>
                    <div>{data.description}</div>
                  </div>
                  <Button
                    color="primary"
                    variant="filled"
                    onClick={() => onSelected(data.id)}
                    disabled={selectedId === data.id}
                  >
                    {selectedId === data.id ? t('currentlyUsedTemplates') : t('useTemplate')}
                  </Button>
                </Template>
              ))}
            </TemplateWrapper>
          ) : (
            <EmptyBlock>
              <DoctorSvg />
              <SectionDescription>{t('clearedTemplates')}</SectionDescription>
              {/* create_groupMessage || create_patientTracking */}
              <Link to={`/message_center/templates/create_${templateType}`}>
                <IconButton variant="ghost" color="primary">
                  <i className="ri-add-line"></i>
                  {t('createTemplate')}
                </IconButton>
              </Link>
            </EmptyBlock>
          )}
        </React.Fragment>
      )}
    </Modal>
  );
};

export default memo(TemplateListModal);
