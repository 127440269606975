import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dashboard_v2/Dialog';
import FormControl from 'components/Dashboard_v2/FormControl';
import Input from 'components/Dashboard_v2/Input';
import { t } from 'i18n/config';

const TitleModal = ({ data, onConfirm, onClose }) => {
  const [title, setTitle] = useState(data);

  const handleConfirm = () => {
    onConfirm(title);
    onClose();
  };

  return (
    <Dialog
      open={true}
      title={t('templateName')}
      description={
        <React.Fragment>
          <FormControl>
            <Input
              label={t('itemName')}
              value={title}
              onInputChange={(e) => setTitle(e.target.value)}
              maxLength={20}
              placeholder={t('examplePatientTrackingName')}
              required
            />
          </FormControl>
        </React.Fragment>
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
      disabled={!title}
    />
  );
};

const propTypes = {
  data: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

TitleModal.propTypes = propTypes;

export default memo(TitleModal);
