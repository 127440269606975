import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { omit } from 'lodash-es';
import moment from 'moment';
import styled from 'styled-components';

import {
  getTemplateCategories,
  getTemplate,
  getTemplates,
  createTemplate,
  updateTemplateCategories,
  deleteTemplate,
} from 'lib/messageCenterService';
import { dashboard_v2 } from 'theme';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';
import SideBar from '../Sidebar';
import DoctorSvg from 'components/Dashboard_v2/Svg/DoctorSvg';
import Text from 'components/Dashboard_v2/Text';
import Button from 'components/Dashboard_v2/Button';
import Select from 'components/Dashboard_v2/Select';
import Dialog from 'components/Dashboard_v2/Dialog';
import MorePopover from './components/MorePopover';
import CategoryEditor from './components/CategoryEditor';
import { t } from 'i18n/config';
import { getClientRegionByVendor } from 'utils/vendors';
import { getLocaleByClientRegion } from 'utils/locale';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  margin: 0 -30px;
`;

const Main = styled.div`
  padding: 30px 30px 30px 86px;
`;

const Header = styled.div`
  padding: 5px 30px 0 30px;
  margin: -30px -30px 30px;
`;

const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SectionTitle = styled.h5`
  margin-top: 40px;
  font-size: 28px;
`;

const SectionDescription = styled.p`
  margin: 24px 0;
  font-size: 14px;
  color: ${colors.SHADES_400};
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
    margin-right: 8px;
  }
`;

const Content = styled.div``;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 32px;
  > div:first-child {
    display: flex;
    flex-wrap: wrap;
    margin-right: 32px;
  }
`;

const Category = styled.div`
  padding: 12px 24px;
  font-size: 16px;
  color: ${({ isSelected }) => (isSelected ? colors.PRIMARY_500 : colors.SHADES_500)};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${colors.PRIMARY_500};
  }
`;

const CategoryEditButton = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  i {
    margin-right: 8px;
    font-size: 20px;
    line-height: 1;
    color: ${colors.SHADES_500};
  }
`;

const TemplateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 18px;
`;

const Template = styled.div`
  position: relative;
  padding: 32px;
  width: calc(33% - 10px);
  border-radius: 16px;
  background-color: ${colors.SHADES_000};
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
  > span {
    display: inline-block;
    padding: 4px 8px;
    margin-bottom: 24px;
    color: ${colors.SHADES_500};
    background: #f1f5f9;
    border-radius: 100px;
  }
  > div {
    margin-bottom: 32px;
    > div {
      font-size: 20px;
      color: ${colors.SHADES_900};
      margin-bottom: 8px;
    }
    > span {
      display: block;
      color: ${colors.SHADES_500};
      font-size: 14px;
    }
  }
  i {
    position: absolute;
    right: 24px;
    top: 16px;
    font-size: 20px;
    color: ${colors.SHADES_400};
    cursor: pointer;
  }
`;

const DeleteText = styled.div`
  padding: 12px 24px;
  font-size: 16px;
  color: ${colors.SHADES_500};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${colors.SHADES_000} !important;

    background-color: ${colors.SYSTEM_ERROR_500};
  }
`;

const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
  a {
    text-decoration: none;
  }
`;

export const SYSTEM_CLIENT_ID = '00000000-0000-0000-0000-000000000000';

const TEMPLATE_TYPES = [
  { id: 1, name: t('groupMessage'), value: 'groupMessage' },
  { id: 2, name: t('patientTracking'), value: 'patientTracking' },
];

const CATEGORY_ALL = { id: 'all', name: 'all' };

const groupTemplateList = (list) =>
  list.reduce(
    (group, data) => {
      const type = data.templateType;
      const category = data.messageTemplateCategoryName;
      if (!group[type][category]) {
        Object.assign(group[type], { [category]: [data] });
      } else {
        group[type][category] = [...group[type][category], data];
      }
      group[type].all = [...group[type].all, data];
      return group;
    },
    { groupMessage: { all: [] }, patientTracking: { all: [] } }
  );

const TemplateList = () => {
  const presetType = TEMPLATE_TYPES.find(
    (type) => type.value === new URLSearchParams(window.location.search).get('type')
  );
  const { renderAlert, setAlert } = useAlert({ unmountClear: true });
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const clients = useSelector((state) => state.clients.byId);
  const currentClient = clients[clientId];
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [templateType, setTemplateType] = useState(presetType || TEMPLATE_TYPES[0]);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(CATEGORY_ALL);
  const [templateList, setTemplateList] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [templateMenuOpenId, setTemplateMenuOpenId] = useState(null);
  const [templateAnchorEl, setTemplateAnchorEl] = useState(null);
  const [templateDeleteId, setTemplateDeleteId] = useState(null);

  const listData = useMemo(
    () => (templateList && templateList[templateType.value][selectedCategory.name]) || [],
    [templateList, templateType, selectedCategory]
  );

  const listCategory = useMemo(
    () => (categories && [CATEGORY_ALL, ...categories[templateType.value]]) || [],
    [categories, templateType]
  );

  const openTemplateMenu = (e, index) => {
    setTemplateMenuOpenId(index);
    setTemplateAnchorEl(e.currentTarget);
  };

  const closeTemplateMenu = () => {
    setTemplateMenuOpenId(null);
    setTemplateAnchorEl(null);
  };

  const handleTypeSelect = useCallback((option) => {
    setSelectedCategory(CATEGORY_ALL);
    setTemplateType(option);
  }, []);

  const handleCategoriesUpdate = async (data) => {
    setIsLoading(true);
    try {
      const newCategories = { ...categories, [templateType.value]: data };
      const flatCategories = [...newCategories['groupMessage'], ...newCategories['patientTracking']];
      await updateTemplateCategories({ clientId, categories: flatCategories });
      await getTemplateData();
      setAlert({ type: 'success', title: t('successfulCategoryUpdate') });
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: `${t('categoryUpdateFailed')}${error && `: ${error}`}` });
      console.error(errRes);
    } finally {
      setCategoryAnchorEl(null);
      setIsLoading(false);
    }
  };

  const handleCloneTemplate = async (templateId) => {
    setIsLoading(true);
    try {
      const templateData = await getTemplate({ clientId, templateId });
      const title = `${templateData.title} (${moment().format('M/D hh:mm')}${t('copy')})`;
      templateData.title = title;
      if (templateData.clientId === SYSTEM_CLIENT_ID) {
        templateData.messageTemplateCategoryId = null;
        templateData.messageTemplateCategoryName = null;
      }
      const payload = omit(templateData, ['id', 'clientId', 'createdAt', 'updatedAt']);
      await createTemplate({ clientId, payload });
      setAlert({ type: 'success', title: t('successfulTemplateCopy', { title }) });
      getTemplateData();
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: `${t('templateCopyFailed')}${error && `: ${error}`}` });
      console.error(errRes);
    } finally {
      setIsLoading(false);
    }
  };

  const openTemplateDeleteDialog = (id) => {
    closeTemplateMenu();
    setTemplateDeleteId(id);
  };

  const handleTemplateDelete = async () => {
    setIsLoading(true);
    try {
      await deleteTemplate({ clientId, templateId: templateDeleteId });
      await getTemplateData();
      setAlert({ type: 'success', title: t('successfulTemplateDeletion') });
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: `${t('templateDeletionFailed')}${error && `: ${error}`}` });
      console.error(errRes);
    } finally {
      setTemplateDeleteId(null);
      setIsLoading(false);
    }
  };

  const getCategoryName = useCallback((name) => {
    switch (name) {
      case 'all':
        return t('all');
      case '系統預設':
        return t('systemDefault');
      default:
        return name;
    }
  }, []);

  const getTemplateData = useCallback(async () => {
    setIsLoading(true);
    try {
      const categories = await getTemplateCategories({ clientId });
      const locale = getLocaleByClientRegion(getClientRegionByVendor(currentClient.vendor));
      const list = await getTemplates({ clientId, locale });
      list.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)));
      setCategories(categories);
      setSelectedCategory(CATEGORY_ALL);
      setTemplateList(groupTemplateList(list));
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: t('loadListFailed', { error: error || error.message }) });
      console.error(errRes);
    } finally {
      setIsInit(true);
      setIsLoading(false);
    }
  }, [clientId, currentClient.vendor, setAlert]);

  useEffect(() => {
    getTemplateData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="animated fadeIn">
      {renderAlert()}
      <SideBar />
      <Main>
        <Header>
          <SectionTitle>{t('messageTemplateLibrary')}</SectionTitle>
          <SectionDescription>{t('messageTemplateSettingDescription')}</SectionDescription>
        </Header>
        <Content>
          {isInit && (
            <React.Fragment>
              {/* Header */}
              <ToolBar>
                <Select options={TEMPLATE_TYPES} selectedOption={templateType} setSelectedOption={handleTypeSelect} />
                <IconButton color="primary" variant="filled" onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                  <i className="ri-add-line" />
                  {t('create')}
                </IconButton>
                <MorePopover anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)}>
                  <Link to="/message_center/templates/create_groupMessage">
                    <i className="ri-mail-send-line" />
                    {t('groupMessage')}
                  </Link>
                  <Link to="/message_center/templates/create_patientTracking">
                    <i className="ri-organization-chart" />
                    {t('patientTracking')}
                  </Link>
                </MorePopover>
              </ToolBar>

              {/* Category */}
              <CategoryWrapper>
                <div>
                  {listCategory.map((category) => (
                    <Category
                      onClick={() => setSelectedCategory(category)}
                      isSelected={category.name === selectedCategory.name}
                      key={category.id}
                    >
                      {getCategoryName(category.name)}
                    </Category>
                  ))}
                </div>
                <CategoryEditButton onClick={(e) => setCategoryAnchorEl(e.currentTarget)}>
                  <i className="ri-settings-5-fill" />
                  <Text font="Body/16px_Regular" color="SHADES_500">
                    {t('editCategory')}
                  </Text>
                </CategoryEditButton>
                <MorePopover anchorEl={categoryAnchorEl} onClose={() => setCategoryAnchorEl(null)}>
                  <CategoryEditor
                    type={templateType.value}
                    data={listCategory}
                    onSave={(data) => handleCategoriesUpdate(data)}
                    isLoading={isLoading}
                  />
                </MorePopover>
              </CategoryWrapper>

              {/* Template list */}
              {listData && listData.length ? (
                <TemplateWrapper>
                  {listData.map((data) => (
                    <Template key={data.id}>
                      <span>{data.messageTemplateCategoryName || t('noCategory')}</span>
                      <div>
                        <div>{data.title}</div>
                        <span>{data.description}</span>
                      </div>
                      <i className="ri-more-fill" onClick={(e) => openTemplateMenu(e, data.id)} />
                      {data.id === templateMenuOpenId && (
                        <MorePopover anchorEl={templateAnchorEl} onClose={closeTemplateMenu}>
                          {/* edit_groupMessage || edit_patientTracking */}
                          {data.clientId && data.clientId !== SYSTEM_CLIENT_ID ? (
                            <>
                              <Link to={`/message_center/templates/edit_${templateType.value}/${data.id}`}>
                                {t('edit')}
                              </Link>
                              <span onClick={() => handleCloneTemplate(data.id)}>{t('copy')}</span>
                              <DeleteText onClick={() => openTemplateDeleteDialog(data.id)}>{t('delete')}</DeleteText>
                            </>
                          ) : (
                            <span onClick={() => handleCloneTemplate(data.id)}>{t('copy')}</span>
                          )}
                        </MorePopover>
                      )}
                    </Template>
                  ))}
                </TemplateWrapper>
              ) : (
                <EmptyBlock>
                  <DoctorSvg />
                  <SectionDescription>{t('messageTemplateCleared')}</SectionDescription>
                  {/* create_groupMessage || create_patientTracking */}
                  <Link to={`/message_center/templates/create_${templateType.value}`}>
                    <IconButton variant="ghost" color="primary">
                      <i className="ri-add-line"></i>
                      {t('createMessageTemplate')}
                    </IconButton>
                  </Link>
                </EmptyBlock>
              )}

              {/* Delete Modal */}
              <Dialog
                open={templateDeleteId !== null}
                title={t('confirmDeletion')}
                description={t('deletionIrreversible')}
                confirmText={t('delete')}
                cancelText={t('cancel')}
                onConfirm={handleTemplateDelete}
                onCancel={() => setTemplateDeleteId(null)}
                confirmColorType="error"
                isLoading={isLoading}
              />
            </React.Fragment>
          )}
        </Content>
      </Main>
    </Container>
  );
};

export default connectData(TemplateList);
