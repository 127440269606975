export const getLocaleByClientRegion = (region) => {
  switch (region) {
    case 'jp':
      return 'jp';
    case 'zh':
      return 'zh-hant';
    default:
      return 'en';
  }
};
